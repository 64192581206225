import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
//import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { LocationStrategy, Location, PathLocationStrategy, HashLocationStrategy } from '@angular/common';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

// import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
//import { NgSelectModule } from '@ng-select/ng-select';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { DrillProcessComponent } from './views/Adminstration/DrillSurvey-Process.Component';
import { FormsModule, ReactiveFormsModule, FormControl, FormGroupDirective, NgForm, Validators, FormGroup, AbstractControl } from '@angular/forms';
import { AuthService } from "./services/auth.service";
import { HttpClientModule } from '@angular/common/http'
import { AppInfoService } from "./services/app.info.service";
import { ToastrModule } from 'ngx-toastr';
import { DataTablesModule } from 'angular-datatables';
import { JwtModule } from '@auth0/angular-jwt';
//import { ModalModule } from 'ngx-bootstrap';
import { ModalModule } from 'ngx-bootstrap/modal'
import { ConfirmModalComponent } from './views/Confimation-Dialog/ConfimationDialogmodal.component';
// import { ConfirmDialogService } from './views/confirm-dialog/confirm-dialog.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './services/auth.interceptor';
import { LoaderInterceptor } from './services/loader.interceptor';


const APP_CONTAINERS = [
  DefaultLayoutComponent
];


import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,


} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';

import { NgxUiLoaderModule, NgxUiLoaderConfig, SPINNER, POSITION, PB_DIRECTION, NgxUiLoaderRouterModule, NgxUiLoaderHttpModule } from 'ngx-ui-loader';

const ngxUiLoaderConfig: NgxUiLoaderConfig =
{
  "bgsColor": "#8CA084",
  "bgsOpacity": 0.5,
  "bgsPosition": "bottom-right",
  "bgsSize": 60,
  "bgsType": "ball-spin-clockwise",
  "blur": 5,
  "delay": 0,
  "fgsColor": "#464648",
  "fgsPosition": "center-center",
  "fgsSize": 30,
  "fgsType": "cube-grid",
  "gap": 24,
  "logoPosition": "center-center",
  "logoSize": 90,
  "logoUrl": "../assets/img/brand/logo4x4.png",
  "masterLoaderId": "master",
  "overlayBorderRadius": "0",
  "overlayColor": "rgba(255, 255, 255, 0.8)",
  "pbColor": "#464648",
  "pbDirection": "ltr",
  "pbThickness": 3,
  "hasProgressBar": true,
  "text": "",
  "textColor": "#FFFFFF",
  "textPosition": "center-center",
  "maxTime": -1,
  "minTime": 30
}
export function tokenGetter() {
  return localStorage.getItem("access_token");
}


@NgModule({
  imports: [
    BrowserModule,
    ModalModule.forRoot(),
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    // NgSelectModule,
    ChartsModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    DataTablesModule,
    // NgMultiSelectDropDownModule.forRoot(),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    //NgxUiLoaderHttpModule.forRoot({
    //  showForeground: true,
    //  exclude: [
    //    '*',
    //    '**/*',
    //            '/auth/login'
    //            ]
    //}),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
      }
    })



  ],
  declarations: [
    AppComponent,
    APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
    RegisterComponent,
    DrillProcessComponent,
    ConfirmModalComponent
    // ModalPopupComponent


  ],
  exports: [
   // ConfirmDialogComponent
  ],
  //providers: [
  ////  {
  ////  provide: LocationStrategy,
  ////  useClass: HashLocationStrategy
  ////},
  //  Location, { provide: LocationStrategy, useClass: PathLocationStrategy },
  //  AuthService,
  //  AppInfoService,
  // // ConfirmDialogService
  //],
  providers: [{
    provide: LocationStrategy,
    useClass: HashLocationStrategy
  },
    AuthService,
    AppInfoService,
    // ConfirmDialogService
    ,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
    // Add this new provider for the interceptor
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  entryComponents: [
    ConfirmModalComponent,
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
